<template>
    <div>
        <div class="flex flex-col items-center justify-center flex-1 h-full pb-8">
            <p class="text-lg font-medium my-12 py-3 px-6 border-2 border-dashed border-gray-200"> {{ floorplan.name }}</p>
            <Icon name="alert" class="w-32 h-32 text-red-500" />
            <p class="text-red-500 font-bold">Are you sure you want to delete the floor plan?</p>
            <button class="btn-danger mt-8" @click="destroy">Yes, Delete</button>
        </div>
    </div>
</template>

<script>
    import Icon from "@/components/ui/Icon";
    import {mapActions, mapGetters} from "vuex";
    import ModalNavigation from "@/mixins/ModalNavigation";

    export default {
        mixins: [ ModalNavigation ],
        components: { Icon},
        data: () => {
            return {
                website: {},
                floorplan: {},
            }
        },
        computed: {
            ...mapGetters({
                getFloorplanByName: 'floorplans/getFloorplanByName',
                backModal: 'modals/getLastModal',
                getWebsiteInfo: 'website_info/getWebsiteInfo'
            }),
        },
        methods: {
            ...mapActions({
                loadFloorplansInStore: 'floorplans/loadFloorplans'
            }),
            destroy() {
                this.$cmsDataProvider.delete('deleteFloorplan', { communityId: this.website.communities_id, id: this.floorplan.name})
                .then( () => {
                    this.$router.push({ name: this.backModal.routeName, params: {FloorplanStatus:'FloorplanDeleted'  }}).then( () => {} );
                });
            }          
        },
        async mounted() {
            this.website = this.getWebsiteInfo
            this.floorplan = this.getFloorplanByName(this.$route.params.floorplanName);
            this.floorplan.community_floorplan_name = this.floorplan.name;
        },
    }
</script>

<style scoped>
.floorplan-photo .remove-photo {
    display: none;
}

.floorplan-photo:hover .remove-photo {
    display: block;
}
</style>